<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-roles' }">{{
            $t("ROLES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingRole"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
          <template v-if="!firstLoader">
            <label>{{ $t("PERMISSIONS") }}</label>
            <v-checkbox
              id="selectAllPermissions"
              v-model="selectAllPermissions"
              :indeterminate="selectAllIndeterminate"
            >
              <template v-slot:label>
                <strong>{{ $t("SELECT_ALL") }}</strong>
              </template>
            </v-checkbox>
            <v-divider v-if="!firstLoader"></v-divider>
            <b-form-row v-if="!firstLoader">
              <b-col
                :sm="3"
                :md="3"
                :lg="2"
                v-for="(permission, index) in permissions.items"
                v-bind:key="'permission-key-' + index"
              >
                <v-checkbox
                  style="font-size: 10px"
                  :id="'permission-' + index"
                  v-model="form['permissions']"
                  :label="permission.name"
                  :value="permission"
                ></v-checkbox>
              </b-col>
            </b-form-row>
          </template>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
          <v-overlay :value="!firstLoader && isLoadingRole">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingRole"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_ROLE, FETCH_PERMISSIONS } from "@/modules/role/store/role.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      role: {},
      permissions: [],
      permissionsParams: {
        page: 1,
        itemsPerPage: 500,
        query: ""
      }
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Roles", route: { name: "list-roles" } },
      { title: "Edit Role" }
    ]);
    vm.generateFormOutOfSchemaJson(this.schemaJson);
    vm.$set(vm.form, "permissions", []);
    vm.getPermissions();
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingRole", "getLanguages"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "role-name-input",
            groupId: "role-name-group",
            required: "required",
            feedback_id: "role-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "name"
          },
          {
            type: "input",
            inputType: "text",
            id: "role-slug-input",
            groupId: "role-slug-group",
            required: "required",
            feedback_id: "role-live-feedback",
            divider: false,
            i18n: {
              label: "SLUG",
              placeholder: "SLUG",
              validation: "VALID_SLUG"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "slug"
          }
        ]
      };
    },
    selectAllIndeterminate: {
      get: function() {
        let vm = this;
        return vm.permissions.items
          ? vm.form.permissions.length < vm.permissions.items.length &&
              vm.form.permissions.length > 0
          : false;
      },
      set: function() {}
    },
    selectAllPermissions: {
      get: function() {
        let vm = this;
        return vm.permissions.items
          ? vm.form.permissions.length == vm.permissions.items.length
          : false;
      },
      set: function(value) {
        let vm = this;
        var selected = [];

        if (value) {
          vm.permissions.items.forEach(function(permission) {
            selected.push(permission);
          });
        }

        vm.form.permissions = selected;
      }
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    const passwordValidations = {
      required: true,
      minLength: 6
    };
    if ("password" in vm.form) {
      tmpValidationObject.form["password"] = vm.setItemValidations(
        passwordValidations
      );
      return tmpValidationObject;
    } else {
      return tmpValidationObject;
    }
  },
  methods: {
    getPermissions(search, loading = function() {}) {
      let vm = this;
      let params = this.permissionsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_PERMISSIONS, apiParams)
        .then(data => {
          vm.permissions = data.data;
          vm.$nextTick(function() {
            vm.firstLoader = false;
          });
        })
        .catch(response => {
          console.log(response);
        })
        .finally(() => {
          loading(false);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_ROLE, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-role",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-role" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-roles" });
          }
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
